let PENDING_REQUESTS = 0

export default ({ app }) => {
  const MAX_REQUESTS_COUNT = 50

  app.$vsf.$gemini.client.interceptors.request.use((config): Promise<any> => {
    return new Promise((resolve) => {
      if (PENDING_REQUESTS > MAX_REQUESTS_COUNT) {
        console.log("PENDING_REQUESTS -->", PENDING_REQUESTS)
      }
      PENDING_REQUESTS++
      resolve(config)
    })
  });

  app.$vsf.$gemini.client.interceptors.response.use(function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  }, function (error) {
    console.log("error in interceptors.response", PENDING_REQUESTS)
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  });

};
