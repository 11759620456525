// eslint-disable-next-line import/no-extraneous-dependencies
import { NuxtAppOptions } from '@nuxt/types';
import axios, { AxiosResponse } from 'axios';

interface IpifyResponse extends AxiosResponse {
  data: {
    ip: string;
  };
}

interface IpapiResponse extends AxiosResponse {
  data: {
    country_code: string;
    country_name: string;
    location: {
      country_flag: string;
    };
    code: string;
    detail: string;
    error: string;
  };
}

const getIp = async () => {
  try {
    const ipResponse: IpifyResponse = await axios({
      method: 'get',
      url: 'https://api.ipify.org',
      params: {
        format: 'json',
      },
    });
    return ipResponse.data.ip;
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getLocationByIp = async (app: NuxtAppOptions, clientIp: string) => {
  try {
    const { i18n } = app;
    const response: IpapiResponse = await axios({
      method: 'get',
      url: `${process.env.GEOLOCATION_ENDPOINT}/${clientIp}`,
      params: {
        access_key: process.env.GEOLOCATION_ACCESS_KEY,
        output: 'json',
        fields: 'country_name,country_code,location',
      },
      headers: {
        Accept: 'text/plain, json/application',
      },
    });
    if (response.data?.error) {
      throw new Error(response.data.error);
    } else if (response.data?.detail === 'Not Found') {
      throw new Error('Location not found');
    }
    let redirectDestination = 'it';
    // availableLocale not accepting a type, hence the use of any and eslint ban comments
    i18n.locales.forEach((availableLocale): void => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      availableLocale.countries.forEach((country: string) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        if (country.toLowerCase() === response.data?.country_code?.toLowerCase() && availableLocale.code !== 'en') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          redirectDestination = availableLocale.code as string;
        }
      });
    });
    console.debug(`Geolocator triggered for ip [${clientIp}]. Found target locale: [${redirectDestination}] | Full response: [${response.data}]`);
    return {
      redirectDestination,
      locationData: {
        label: response.data?.country_name,
        code: response.data?.country_code?.toLowerCase(),
        flag: response.data?.location?.country_flag,
      },
    };
  } catch (err) {
    const errorMessage = `Unable to get location: ${err}`;
    throw new Error(errorMessage);
  }
};

export const cleanQuery = (query) => {
  const invalidKeys = ['page', 'sort', 'term', 'itemsPerPage', 'size', 'price'];
  invalidKeys.forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (Object.keys(query).includes(key)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      delete query[key];
    }
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return query;
};

/* ToDo: to avoid redirecting in home, call first GetUrlRewrite to know the target path. Once the path is a grn, then use
    this function to redirect to the correct path */
// const getUrlRewrites = async (path: string) => {
//   const geminiData = await axios({
//     method: 'post',
//     url: process.env.GEMINI_URL_MANAGER_ENDPOINT,
//     data: {
//       targetPaths: [path],
//       tenantId: process.env.TENANT_ID,
//     },
//     headers: {
//       Authorization: `Bearer ${process.env.GEMINI_RESOURCE_JWT}`,
//     },
//   });
//   if (geminiData?.data?.urlRewrites) {
//     // eslint-disable-next-line @typescript-eslint/no-unsafe-return
//     return geminiData.data.urlRewrites;
//   }
//   return [];
// };

// include a string error, since the function used in redirect.ts may return one if getLocationByIp throws one
export interface GetLocaleResponse {
  redirectDestination?: string;
  locationData?: {
    label: string;
    code: string;
    flag: string;
  };
  error?: string;
}
