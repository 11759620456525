import { ILocale } from './types/geolocation';

export const locales: ILocale[] = [
  {
    code: 'it',
    file: 'it.js',
    iso: 'it_IT',
    defaultCurrency: 'EUR',
    countries: ['IT', 'SM', 'VA'],
  },
  {
    code: 'en',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'EUR',
    countries: ['IT', 'SM', 'VA'],
  },
  {
    code: 'fr',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'EUR',
    countries: ['FR', 'MC'],
  },
  {
    code: 'uk',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'GBP',
    countries: ['GB'],
  },
  {
    code: 'ch',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'CHF',
    countries: ['CH'],
  },
  {
    code: 'us',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'USD',
    countries: ['US'],
  },
  {
    code: 'cn',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'CNY',
    countries: ['CN'],
  },
  {
    code: 'au',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'AUD',
    countries: ['AU', 'NZ'],
  },
  {
    code: 'ca',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'CAD',
    countries: ['CA'],
  },
  {
    code: 'eue',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'EUR',
    countries: ['BG', 'HR', 'CY', 'CZ', 'EE', 'GR', 'HU', 'LV', 'LT', 'RO', 'SK', 'SI'],
  },
  {
    code: 'eu',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'EUR',
    countries: ['DK', 'FI', 'IE', 'SE', 'AT', 'BE', 'DE', 'LU', 'MT', 'NL', 'PL', 'PT', 'ES'],
  },
  {
    code: 'row',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'EUR',
    countries: [
      'DZ',
      'TR',
      'KY',
      'AI',
      'AS',
      'AF',
      'AM',
      'AZ',
      'BD',
      'BT',
      'BN',
      'KH',
      'IN',
      'ID',
      'IR',
      'IQ',
      'IL',
      'JO',
      'KW',
      'KG',
      'LA',
      'LB',
      'MY',
      'MV',
      'MN',
      'MM',
      'NP',
      'OM',
      'PK',
      'PH',
      'SA',
      'LK',
      'SY',
      'TW',
      'TJ',
      'TM',
      'UZ',
      'VN',
      'YE',
      'CK',
      'FJ',
      'PF',
      'KI',
      'MH',
      'MQ',
      'FM',
      'NR',
      'NC',
      'NU',
      'NF',
      'MP',
      'PW',
      'PG',
      'PN',
      'KN',
      'WS',
      'SB',
      'TK',
      'TO',
      'TV',
      'VU',
      'WF',
      'AO',
      'BJ',
      'BW',
      'BF',
      'BI',
      'CM',
      'CV',
      'CF',
      'TD',
      'KM',
      'CG',
      'CD',
      'CI',
      'DJ',
      'EG',
      'ER',
      'ET',
      'GA',
      'GM',
      'GH',
      'GI',
      'GN',
      'GW',
      'KE',
      'LS',
      'LR',
      'LY',
      'MG',
      'MW',
      'ML',
      'MR',
      'MU',
      'MA',
      'MZ',
      'NA',
      'NE',
      'NG',
      'RE',
      'RW',
      'SH',
      'ST',
      'SN',
      'SC',
      'SL',
      'SO',
      'ZA',
      'SD',
      'SZ',
      'TZ',
      'TG',
      'TN',
      'UG',
      'EH',
      'ZM',
      'ZW',
      'AG',
      'AR',
      'AW',
      'BS',
      'BB',
      'BZ',
      'BM',
      'BO',
      'BR',
      'CL',
      'CO',
      'CR',
      'CW',
      'DM',
      'EC',
      'SV',
      'GQ',
      'FK',
      'GF',
      'GD',
      'GP',
      'GU',
      'GY',
      'HT',
      'HN',
      'JM',
      'MX',
      'MS',
      'NI',
      'PA',
      'PY',
      'PE',
      'PR',
      'LC',
      'VC',
      'SR',
      'TT',
      'TC',
      'UY',
      'VE',
      'VG',
      'VI',
      'CU',
      'DO',
      'GT',
      'PM',
    ],
  },
  {
    code: 'jp',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'JPY',
    countries: ['JP'],
  },
  {
    code: 'hk',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'HKD',
    countries: ['HK'],
  },
  {
    code: 'mo',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'MOP',
    countries: ['MO'],
  },
  {
    code: 'kr',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'KRW',
    countries: ['KR'],
  },
  {
    code: 'uae',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'AED',
    countries: ['AE'],
  },
  {
    code: 'sg',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'SGD',
    countries: ['SG'],
  },
  {
    code: 'th',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'THB',
    countries: ['TH'],
  },
  {
    code: 'qa',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'QAR',
    countries: ['QA'],
  },
  {
    code: 'bh',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'BHD',
    countries: ['BH'],
  },
  {
    code: 'exu',
    file: 'en.js',
    iso: 'en_US',
    defaultCurrency: 'EUR',
    countries: ['AL', 'AD', 'BA', 'GE', 'GL', 'IS', 'LI', 'MD', 'ME', 'MK', 'NO', 'RS', 'SJ', 'FO'],
  },
];

export const numberFormats = {
  it: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  en: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  uk: {
    currency: {
      style: 'currency',
      currency: 'GBP',
      currencyDisplay: 'symbol',
    },
  },
  ch: {
    currency: {
      style: 'currency',
      currency: 'CHF',
      currencyDisplay: 'symbol',
    },
  },
  us: {
    currency: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
    },
  },
  cn: {
    currency: {
      style: 'currency',
      currency: 'CNY',
      currencyDisplay: 'symbol',
    },
  },
  au: {
    currency: {
      style: 'currency',
      currency: 'AUD',
      currencyDisplay: 'symbol',
    },
  },
  ca: {
    currency: {
      style: 'currency',
      currency: 'CAD',
      currencyDisplay: 'symbol',
    },
  },
  eue: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  eu: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  row: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
  jp: {
    currency: {
      style: 'currency',
      currency: 'JPY',
      currencyDisplay: 'symbol',
    },
  },
  hk: {
    currency: {
      style: 'currency',
      currency: 'HKD',
      currencyDisplay: 'symbol',
    },
  },
  mo: {
    currency: {
      style: 'currency',
      currency: 'MOP',
      currencyDisplay: 'symbol',
    },
  },
  kr: {
    currency: {
      style: 'currency',
      currency: 'KRW',
      currencyDisplay: 'symbol',
    },
  },
  uae: {
    currency: {
      style: 'currency',
      currency: 'AED',
      currencyDisplay: 'symbol',
    },
  },
  sg: {
    currency: {
      style: 'currency',
      currency: 'SGD',
      currencyDisplay: 'symbol',
    },
  },
  th: {
    currency: {
      style: 'currency',
      currency: 'THB',
      currencyDisplay: 'symbol',
    },
  },
  qa: {
    currency: {
      style: 'currency',
      currency: 'QAR',
      currencyDisplay: 'symbol',
    },
  },
  bh: {
    currency: {
      style: 'currency',
      currency: 'BHD',
      currencyDisplay: 'symbol',
    },
  },
  exu: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    },
  },
};

export const magentoStoreviewCodesMapping = [
  { magentoStoreViewCode: 'it_it', geminiCode: 'it' },
  { magentoStoreViewCode: 'it_en', geminiCode: 'it' },
  { magentoStoreViewCode: 'fr_en', geminiCode: 'fr' },
  { magentoStoreViewCode: 'ch_en', geminiCode: 'ch' },
  { magentoStoreViewCode: 'ch_it', geminiCode: 'ch' },
  { magentoStoreViewCode: 'uk_en', geminiCode: 'uk' },
  { magentoStoreViewCode: 'de_en', geminiCode: 'eu' },
  { magentoStoreViewCode: 'es_en', geminiCode: 'eu' },
  { magentoStoreViewCode: 'benelux_en', geminiCode: 'eu' },
  { magentoStoreViewCode: 'eu_east_en', geminiCode: 'eue' },
  { magentoStoreViewCode: 'eu_north_en', geminiCode: 'eu' },
  { magentoStoreViewCode: 'us_en', geminiCode: 'us' },
  { magentoStoreViewCode: 'me_en', geminiCode: 'row' },
  { magentoStoreViewCode: 'asia_en', geminiCode: 'row' },
  { magentoStoreViewCode: 'cn_en', geminiCode: 'cn' },
  { magentoStoreViewCode: 'hk_en', geminiCode: 'hk' },
  { magentoStoreViewCode: 'jp_en', geminiCode: 'jp' },
  { magentoStoreViewCode: 'kr_en', geminiCode: 'kr' },
  { magentoStoreViewCode: 'au_en', geminiCode: 'au' },
  { magentoStoreViewCode: 'br_en', geminiCode: 'us' },
  { magentoStoreViewCode: 'row_en', geminiCode: 'row' },
  { magentoStoreViewCode: 'row_eur_en', geminiCode: 'row' },
  { magentoStoreViewCode: 'dk_en', geminiCode: 'eu' },
  { magentoStoreViewCode: 'fi_en', geminiCode: 'eu' },
  { magentoStoreViewCode: 'ie_en', geminiCode: 'eu' },
  { magentoStoreViewCode: 'no_en', geminiCode: 'eu' },
  { magentoStoreViewCode: 'se_en', geminiCode: 'eu' },
  { magentoStoreViewCode: 'ru_en', geminiCode: 'row' },
];
