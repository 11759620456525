/* eslint-disable import/no-extraneous-dependencies */
import { Plugin } from '@nuxt/types';
import { Product, Category } from '@gemini-vsf/composables';
import { useUrlHelpers } from '~/composables';

const generateFallback = ({ product, category }: { product?: Product; category?: Category }): string => {
  return product?.url_key || category?.url_path || '/';
};

const plugin: Plugin = (_, inject) => {
  const { buildProductUrl, buildCategoryUrl } = useUrlHelpers();

  inject('categoryUrl', (category: Category): string => {
    try {
      return buildCategoryUrl(category);
    } catch (error) {
      console.warn('categoryUrl ~ error:', error);
    }
    return generateFallback({ category });
  });
  inject('productUrl', (product: Product): string => {
    try {
      return buildProductUrl(product);
    } catch (error) {
      console.warn('productUrl ~ error:', error);
    }
    return generateFallback({ product });
  });
};

export default plugin;
