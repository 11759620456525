// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types';
import formatCurrency from '~/helpers/formatCurrency';

interface FormatCurrency {
  $fc(value: number | string): string;
  $fc(value: number | string, options?: Intl.NumberFormatOptions): string;
  $fc(value: number | string, locale?: string, options?: Intl.NumberFormatOptions): string;
}

const plugin: Plugin = (context, inject) => {
  inject('fc', (value: number | string, locale?: string, options = {}): string => {
    // eslint-disable-next-line no-param-reassign
    locale = locale || context.i18n?.localeProperties?.iso.replace('_', '-');
    // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-assignment
    options = { currency: context.i18n?.localeProperties?.defaultCurrency || context.app.$vsf.$gemini.config.state.getCurrency(), ...options };
    return formatCurrency(value, locale, options);
  });
};

export default plugin;
